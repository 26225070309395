@import "/src/styles/variables.scss";

.container {
	max-width: $XL;
	padding: 0 calc($XL * 0.05);
	margin: 0 auto;
}

@media only screen and (max-width: $XL) {
	.container {
		max-width: $XL;
		padding: 0 5%;
	}
}

@media only screen and (max-width: $L) {
	.container {
		max-width: $L;
		padding: 0 5%;
	}
}

@media only screen and (max-width: $M) {
	.container {
		max-width: $M;
		padding: 0 5%;
	}
}
