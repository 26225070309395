@import "/src/styles/variables.scss";

.footer__top_container {
	display: flex;
	justify-content: space-between;
	// align-items: center;
	padding: 25px 0px;
	color: $white;
	color: $grayScaleLvl4;
	gap: $flexGap32;
	border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
}

.CTAtext {
	color: white;
}

.footer__top_btn {
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: $flexGap16;
}

.socialImageContainer {
	max-width: 40px;
	height: auto;
	transition: 0.3s;
	position: relative;
	border-radius: 50%;
}

.socialImageContainer:hover::before {
	background-color: white;
	max-width: 30px;
	border-radius: 50%;
	height: 30px;
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 0;
	transition: 0.3s;
	left: 0;
}

.socialImage {
	filter: invert(100%);
	position: relative;
	width: 100%;
	height: auto;
	transition: 0.3s;
}

.socialImageContainer:hover {
	.socialImage {
		filter: invert(0);
		transition: 0.3s;
	}
}

.socialLinksContainer {
	display: flex;
	padding-top: 16px;
	gap: 16px;
}

.textContainer {
	display: flex;
	justify-content: space-between;
	align-items: start;
	flex: 0 0 75%;
}

.link {
	color: white;

	span {
		transition: 0.3s;
		color: $grayScaleLvl4;
	}

	&:hover {
		span {
			transition: 0.3s;
			color: white;
		}
	}
}

@media screen and (max-width: $L) {
	.textContainer {
		flex-direction: column;
		align-items: start;
		flex: unset;
	}

	.textBox {
		text-align: left;
	}

	.footer__top {
		&_container {
			flex-wrap: wrap;
			text-align: center;
		}

		// &_btn {
		// 	text-align: center;
		// 	margin-top: 20px;
		// }
	}
}

.maintain {
	text-decoration: underline;
	transition: 0.3s;

	&:hover {
		color: $white;
	}
}

@media screen and (max-width: $M) {
	.footer__top_container {
		flex-direction: column-reverse;
		align-items: start;
		gap: $flexGap24;
	}

	.footer__top_btn {
		gap: $flexGap8;
	}
}
