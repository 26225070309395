@import "/src/styles/variables.scss";

.center {
	text-align: center;
}

.paragraph {
	// font-family: "Open Sans", sans-serif;

	overflow: hidden;
	line-height: 1.2;
	padding-bottom: 8px;

	//h1
	&__XXL {
		font-size: 3.052rem;
		line-height: 3rem;
		letter-spacing: 0;
	}

	//h2
	&__XL {
		font-size: 2.441rem;
		line-height: 3.5rem;
		letter-spacing: 0;
	}

	//h3
	&__L {
		font-size: 1.953rem;
		line-height: 2.5rem;
		letter-spacing: 0;
	}

	//h4
	&__M {
		font-size: 1.563rem;
		line-height: 2rem;
		letter-spacing: 0;
	}

	//h5
	&__S {
		font-size: 1.25rem;
		line-height: 1.75rem;
		letter-spacing: 0;
	}

	//overline
	&__XS {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}
}

@media only screen and (max-width: $L) {
	.paragraph {
		&__XS {
			font-size: 1.15rem;
			line-height: 1.75rem;
		}
	}
}

@media only screen and (max-width: $M) {
	.paragraph {
		&__XS {
			font-size: 1.25rem;
			line-height: 2rem;
		}
	}
}
