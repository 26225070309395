@import "/src/styles/variables.scss";

.hoveredMenu {
	position: absolute;
	box-shadow: $boxShadowNormal;
	top: 45px;
	left: 0;
	background: $white;
	// border-radius: $borderRNormal;
	z-index: 999;

	&::after {
		content: "";
		position: absolute;
		background: transparent;
		top: -25px;
		left: -10%;
		width: 120%;
		height: 120%;
		z-index: 98;
	}
}

.hoveredMenuBox {
	z-index: 101;
	position: relative;
	// padding: 10px;
	display: flex;
	gap: $flexGap24;
}

.hoveredTitle {
	font-size: 20px;
	font-weight: 400;
	white-space: nowrap;
	padding-bottom: 16px;
	padding-left: 5px;
}

.linkTitle {
	padding-right: 24px;
	position: relative;
}

.hoveredLink {
	padding: 0.5rem 1rem;
	white-space: nowrap;
	position: relative;
	// color: $grayScaleLvl2;
	// padding: 5px 5px;
	// border-radius: $borderRNormal;
	// transition: 0.3s;
	font-size: 16px;
	transition: 0.3s;

	&:hover {
		color: $brandColorShadeLvl1;
		background: $grayScaleLvl5;
		transition: 0.3s;

		& .svgIcon {
			transition: 0.3s;

			color: $brandColorShadeLvl1;
			fill: $brandColorShadeLvl1;

			& path {
				transition: 0.3s;
				color: $brandColorShadeLvl1;
				fill: $brandColorShadeLvl1;
			}
		}
	}
}

.svgIcon {
	transition: 0.3s;
	fill: $black;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	transform: scale(0.6);
}

.hoveredListItem {
	border-bottom: 1px solid $grayScaleLvl4;
}

.descriptionText {
	font-size: 12px;
	color: $grayScaleLvl3;
	width: 300px;
}
