@import "./variables.scss";

// .advisors__dropdown-content {
//     display: none;
// }

// .advisors__list-title {
//     cursor: pointer;
//     text-decoration: underline;
// }

// .advisors__popup-text li {
//     list-style: disc;
// }
.advisors__list-title {
	padding: 16px 0;
}

.advisors__list-title h3 {
	font-size: 1.5rem;
	font-weight: 600 !important;
	font-family: "Open Sans";
}

.advisors__dropdown-content {
	& a {
		color: $brandColor;
		text-decoration: underline;

		&:hover {
			color: $brandColorShadeLvl1;
		}
	}

	& ul {
		margin-left: 16px;
		list-style-image: none;
	}

	& li {
		list-style-type: disc;
		padding: 4px;
		&::marker {
			color: black;
			font-size: 16px;
		}
	}
}
