@import "./variables.scss";
@import "./advisors.scss";
@import "./featureCircle.scss";

/* Open Sans Regular */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

/* Open Sans Italic */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

/* Open Sans 300 */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

/* Open Sans 300 Italic */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

/* Open Sans 500 */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

/* Open Sans 500 Italic */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

/* Open Sans 600 */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

/* Open Sans 600 Italic */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

/* Open Sans 800 */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

/* Open Sans 800 Italic */
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: "Open Sans", sans-serif !important;
	font-size: 16px;
	// scroll-behavior: smooth;
}

select::placeholder,
input::placeholder,
textarea::placeholder {
	font-family: "Open Sans", sans-serif !important;
}

a {
	color: inherit;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

* {
	box-sizing: border-box !important;
}

::before,
::after {
	box-sizing: content-box !important;
}

strong {
	font-weight: 600 !important;
}

// disabling hubspot cookies banner
#hs-eu-cookie-confirmation {
	display: none;
}

@media (prefers-color-scheme: dark) {
	// html {
	// 	color-scheme: dark;
	// }
	// body {
	// 	color: $white;
	// 	background: $black;
	// }
}

:root {
	--brandColor: #be00bd;
	--purple: #be00bd;
	--brandColorShadeLvl0: #e074e8;
	--brandColorShadeLvl1: #e000e0;
	--brandColorShadeLvl2: #8f008f;
	--brandColorShadeLvl3: #5f005f;
	--mui-palette-background-default: white !important;
}

.one {
	--brandColor: #175eeb;
	--purple: #175eeb;
	--brandColorShadeLvl0: #1247b3;
	--brandColorShadeLvl1: #1554d1;
	--brandColorShadeLvl2: #739ef3;
	--brandColorShadeLvl3: #9dbbf7;
}

.hover {
	&__purple:hover {
		color: $purple;
		transition: 0.3s;
	}
}

.alert {
	overflow: hidden;
}

.alert-enter {
	overflow: hidden;
	transition: 0.7s;
	opacity: 0;
}

.alert-enter-active {
	opacity: 1;
	overflow: hidden;
	transition: 0.7s;
}

.swiper-button-next {
	color: $purple !important;
	top: var(--swiper-navigation-top-offset, 50%) !important;
	transform: scale(1) !important;
	font-weight: 400 !important;
}

.swiper-button-prev {
	color: $purple !important;
	top: var(--swiper-navigation-top-offset, 50%) !important;
	transform: scale(1) !important;
	font-weight: 400 !important;
}

.SwiperCustomer .swiper-pagination {
	bottom: -32px !important;
}

.SwiperCustomer .swiper-slide {
	height: auto !important;
}

.SwiperCustomer .swiper-wrapper {
	// align-items: stretch;
}

.pricingSlider .swiper {
	overflow: hidden !important;
}

.pricingSlider .MuiInputBase-input {
	box-sizing: content-box !important;
}

.pricingSlider .swiper-button-next,
.pricingSlider .swiper-button-prev {
	color: $brandColorOne !important;
	transform: scale(0.4) !important;
	top: auto !important;
	font-weight: bold !important;
}

.pricingSlider .swiper-pagination-bullet-active {
	color: $brandColorOne !important;
	background-color: $brandColorOne !important;
}

.pricingSlider .swiper-button-prev {
	left: 50px !important;
}

.pricingSlider .swiper-button-next {
	right: 50px !important;
}

.swiper {
	overflow: visible !important;
}

.swiper-wrapper {
	align-items: center;
}

.swiper-pagination-bullet-active {
	color: $purple !important;
	background-color: $purple !important;
}

.swiper-button-prev {
	left: -5% !important;
}

.swiper-button-next {
	right: -5% !important;
}

.expertisePopupTitle {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	text-align: center;
}

@media screen and (max-width: 1023px) {
	.swiper-button-prev:after,
	.swiper-button-next:after {
		font-size: 25px !important;
	}

	.pricingSlider .swiper-button-prev:after,
	.pricingSlider .swiper-button-next:after {
		font-size: 44px !important;
	}

	.pricingSlider .swiper-button-prev {
		left: 50px !important;
	}

	.pricingSlider .swiper-button-next {
		right: 50px !important;
	}

	.swiper-button-prev {
		left: -5% !important;
	}

	.swiper-button-next {
		right: -5% !important;
	}

	.expertisePopupTitle {
		gap: 16px;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}

@media screen and (max-width: $L) {
	html,
	body {
		// scroll-behavior: smooth;
		font-size: 14px;
	}
}

@media screen and (max-width: $M) {
	html,
	body {
		font-size: 12.79999px;
	}

	.swiper {
		padding: 0 1rem !important;
	}
}

.list-enter {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 700ms ease-in-out, max-height 700ms ease-in-out;
}

.list-enter-active {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 700ms ease-in-out, max-height 700ms ease-in-out;
}

.list-exit {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 700ms ease-in-out, max-height 700ms ease-in-out;
}

.list-exit-active {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 700ms ease-in-out, max-height 700ms;
}

//swiper

.swiper-scrollbar-drag {
	background: black !important;
}
