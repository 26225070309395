@import "/src/styles/variables.scss";

.navListItem {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		background: transparent;
		top: 20px;
		left: 0;
		width: 100%;
		height: 30px;
		z-index: 1;
	}
}

.linkWithImageContainer {
	display: flex;
	align-items: center;
	gap: 3px;

	& span {
		height: 100%;
		display: block;

		& svg {
			height: 100%;
			display: block;
		}
	}

	& svg {
		transition: fill 0.3s;

		fill: $brandColor;
	}
}

.link {
	position: relative;
	z-index: 101;
	padding: 5px 10px;
	font-size: 16px;
	transition: color 0.3s;
	font-weight: 400;
	// text-transform: uppercase;
	position: relative;
	cursor: pointer;

	&.external {
		padding: 5px 24px 5px 10px;
	}

	&.expanded {
		padding: 5px 17px 5px 10px;
		transition: color 0.3s;
	}

	&.shown {
		&.expanded::before {
			color: $brandColorShadeLvl1;
		}

		&.expanded {
			color: $brandColorShadeLvl1;
		}
	}

	&.expanded::before {
		content: "";
		position: absolute;
		// color: $black !important;
		z-index: 100;
		top: -3px;
		right: 2px;
		bottom: 0;
		margin: auto 0;
		height: 6px;
		width: 6px;
		border-right: 2px solid;
		border-bottom: 2px solid;
		transform: rotate(45deg);
		// transition: color 0.3s;
	}
}

.active {
	color: $black;
	font-weight: 800 !important;

	&::after {
		content: "";
		bottom: -28px;
		left: 0;
		position: absolute;
		height: 5px;
		width: 100%;
		background-color: $brandColor;
	}

	&.one::after {
		background-color: $brandColorOne;
	}
}

.svgIcon {
	position: absolute;
	right: 0;
	transition: 0.3s;
	top: 2.5px;
	bottom: 0;
	fill: $black;
	transform: scale(0.6);

	&.withIcon {
		top: 9px;
	}
}

.link:hover {
	color: $brandColorShadeLvl1;
	transition: color 0.3s;

	.linkWithImageContainer {
		& svg {
			transition: fill 0.3s;
			fill: $brandColorShadeLvl1;
		}
	}

	& .svgIcon {
		fill: $brandColorShadeLvl1;
	}

	&.expanded::before {
		color: $brandColorShadeLvl1;
		// transition: color 0.3s;
	}
}

.listEnter {
	overflow: hidden;
	opacity: 1;
	max-height: 1px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listEnterActive {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listExit {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: 0, max-height 300ms ease-in-out;
}

.listExitActive {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: 0, max-height 300ms;
}
