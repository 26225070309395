@import "/src/styles/variables.scss";

.center {
	text-align: center;
}

.title {
	padding-bottom: 8px;
	// font-family: "Open Sans", sans-serif;

	//h1
	&__XXL {
		font-size: 3.552rem;
		line-height: 4.5rem;
		letter-spacing: 0;
	}

	//h1
	&__XL {
		font-size: 3.052rem;
		line-height: 4rem;
		letter-spacing: 0;
	}

	//h2
	&__L {
		font-size: 2.441rem;
		line-height: 3.5rem;
		letter-spacing: 0;
	}

	//h3
	&__M {
		font-size: 1.953rem;
		line-height: 2.5rem;
		letter-spacing: 0;
	}

	//h4
	&__S {
		font-size: 1.563rem;
		line-height: 2rem;
		letter-spacing: 0;
	}

	//h5
	&__XS {
		font-size: 1.25rem;
		line-height: 1.75rem;
		letter-spacing: 0;
	}
}
