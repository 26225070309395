@import "/src/styles/variables.scss";

.container {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 25px;
	color: $white;
}
