@import "/src/styles/variables.scss";

.subHeader {
	width: 100%;
	z-index: 99;
	// transition: 0.3s  ease-in-out;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	box-shadow: $boxShadowNormal;
}

.logo {
	height: 25px;
	width: auto;
}

.logoBox {
	// height: 80px;
	width: auto;
	padding-right: 35px !important;

	&::before {
		color: $white !important;
	}
}

.logoBox.second {
	// height: 60px;
}

.navBox {
	display: flex;
	align-items: center;
	gap: $flexGap16;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
}

.logoWrapper:last-child {
	display: flex;
	position: relative;
	gap: 16px;
}

.navWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $flexGap8;
	max-height: calc(100vh - 120px);
	overflow: auto;
	// box-shadow: 0 2px 8px rgba(0,0,0,.15);
}

.navList {
	display: flex;
	position: relative;
	align-items: center;
	gap: $flexGap32;
}

.link {
	position: relative;
	// z-index: 101;
	padding: 5px 0;
	font-size: 16px;
	transition: color 0.3s ease-in-out;
	font-weight: 400;
	position: relative;
	cursor: pointer;
}

.link:hover {
	// color: $brandColorShadeLvl1;
	// transition: color 0.3s ease-in-out;
}

.button {
	position: relative;
	// z-index: 101;
	text-align: center;
	padding: 10px 20px;
	font-size: 16px;
	transition: color 0.3s, background-color 0.3s;
	font-weight: 400;
	position: relative;
	cursor: pointer;
	border-radius: $borderRNormal;

	&.primary {
		color: $brandColor;
		background-color: $white;
		border: 1px $white solid;

		&:hover {
			// background-color: $brandColorShadeLvl1;
			color: $brandColorShadeLvl1;
			transition: color 0.3s, background-color 0.3s;
		}
	}

	&.secondary {
		color: $white;
		border: 1px $white solid;

		&:hover {
			border: 1px $white solid;
			color: $brandColor;
			background-color: $white;
			transition: color 0.3s, background-color 0.3s;
		}
	}
}

@media screen and (max-width: $L) {
	.navWrapper {
		box-shadow: $boxShadowNormal;
	}

	.subHeader {
		position: relative;

		// &.show::after {
		// 	content: "";
		// 	position: absolute;
		// 	bottom: 0px;
		// 	z-index: 1000;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 8px;
		// 	background: $grayScaleLvl5;
		// }

		// &.hide::after {
		// 	content: "";
		// 	position: absolute;
		// 	bottom: 0px;
		// 	z-index: 1000;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 8px;
		// 	background: $white;
		// }
	}

	.navBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.navWrapper {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background-color: $white;
		transition: transform 0.3s ease-in-out;
		z-index: 999;
	}

	.navList {
		list-style: none;
		padding: 25px 0;
		text-align: center;
		margin: 0;
		height: 100%;
		overflow-y: auto;
		display: block;
		box-shadow: $boxShadowNormal;
	}
}

@media screen and (max-width: $M) {
	.button {
		padding: 10px;
	}
}

@media screen and (max-width: 400px) {
	.button {
		padding: 10px 5px;
	}
}

.menuEnter {
	opacity: 0;
	transform: translateY(-60px);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menuEnterActive {
	opacity: 1;
	transform: translateY(0);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menuExit {
	opacity: 1;
	transform: translateY(0);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menuExitActive {
	opacity: 0;
	transform: translateY(-60px);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
