@import "/src/styles/variables.scss";

.header {
	position: sticky;
	background-color: $white;
	top: 0;
	z-index: 999;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	box-shadow: $boxShadowNormal;

	&.show {
		opacity: 1;
		transform: translateY(0);
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

		.subHeader {
			background-color: $brandColor;
			transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
		}
	}

	&.hide {
		transform: translateY(-126px);
		opacity: 1;
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

		.subHeader {
			transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
			background-color: $brandColor;
		}
	}
}

.secondaryHeaderContainer {
	background-color: $grayScaleLvl5;
	height: 45px;
	display: flex;
	align-items: center;
	// text-align: end;
	width: 100%;
}

.secondaryHeaderList {
	display: flex;
	align-items: center;
	text-align: end;
	justify-content: end;
	width: 100%;
	height: 100%;
	gap: 2rem;
}

.logo {
	height: 100%;
	width: auto;
}

.logoBox {
	height: 80px;
	width: auto;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerButtons {
	display: flex;
	gap: $flexGap16;
	align-items: center;
}

.headerText {
	&.tablet {
		font-size: 16px;
		display: none;
		color: $grayScaleLvl3;
	}
}

.navBox {
	display: flex;
	align-items: center;
	gap: $flexGap48;
}

.menuEnter {
	opacity: 0;
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menuEnterActive {
	box-shadow: $boxShadowNormal;
	opacity: 1;
	transform: translateY(0);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menuExit {
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	box-shadow: $boxShadowNormal;
	opacity: 1;
	transform: translateY(0);
}

.menuExitActive {
	opacity: 0;
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.subHeader {
	top: 0;
	z-index: 90;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

@media screen and (max-width: $L) {
	.header {
		display: none;
	}
}
