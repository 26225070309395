@import "/src/styles/variables.scss";

.navWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $flexGap8;
}

.navList {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: end;
	gap: $flexGap16;

	// text-align: center;
}

.linkClassName {
	font-size: 13px;
	font-weight: 400;

	&::after {
		content: "";
		bottom: -4px;
		left: 0;
		position: absolute;
		height: 5px;
		width: 100%;
		background-color: transparent;
	}
}

.headerText {
	color: $grayScaleLvl3;
	padding-left: 10px;
	padding-top: 5px;
	font-size: 16px;
}

// @media screen and (max-width: $L) {

//     .headerText{
//         display: none;
//     }

// }
