@import "/src/styles/variables.scss";

.center {
	text-align: center;
}

.description {
	padding-bottom: 8px;
	// font-family: "Open Sans", sans-serif;

	&__XXL {
		font-size: 1.25rem;
		line-height: 1.75rem;
		letter-spacing: 0;
	}

	&__XL {
		font-size: 1.25rem;
		line-height: 1.75rem;
		letter-spacing: 0;
	}

	&__L {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}

	&__M {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}

	&__S {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}

	&__XS {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}

	&__XXS {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: 0;
	}
}

@media only screen and (max-width: $L) {
	.description {
		&__L {
			font-size: 1.15rem;
			line-height: 1.95rem;
		}

		&__M {
			font-size: 1.15rem;
			line-height: 1.95rem;
		}

		&__S {
			font-size: 1.15rem;
			line-height: 1.95rem;
		}

		&__XS {
			font-size: 1.15rem;
			line-height: 1.95rem;
		}

		&__XXS {
			font-size: 1.15rem;
			line-height: 1.95rem;
		}
	}
}

@media only screen and (max-width: $M) {
	.description {
		&__L {
			font-size: 1.25rem;
			line-height: 2.05rem;
		}

		&__M {
			font-size: 1.25rem;
			line-height: 2.05rem;
		}

		&__S {
			font-size: 1.25rem;
			line-height: 2.05rem;
		}

		&__XS {
			font-size: 1.25rem;
			line-height: 2.05rem;
		}

		&__XXS {
			font-size: 1.25rem;
			line-height: 2.05rem;
		}
	}
}
