@import "/src/styles/variables.scss";

.navListItem {
	position: relative;
	text-align: center;
	color: $white;
}

.active {
	color: $white;
	font-weight: 800 !important;
	position: relative;
}

.stylesLink:hover {
	color: $white;
}

@media screen and (max-width: $L) {
	.navListItem {
		padding: 8px 0;
		max-width: 80%;
		margin: 0 auto;
	}
	.navListItem {
		color: $black;
	}

	.active {
		color: $brandColor;
	}
}
