@import "/src/styles/variables.scss";

.navWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $flexGap8;
}

.navList {
	display: flex;
	position: relative;
	align-items: center;
	gap: $flexGap32;
	// text-align: center;
}

.headerText {
	color: $grayScaleLvl3;
	padding-left: 10px;
	padding-top: 5px;
	font-size: 16px;
}

@media screen and (max-width: $XL) {
	.navList {
		gap: $flexGap16;
	}
}

// @media screen and (max-width: $L) {

//     .headerText{
//         display: none;
//     }

// }
