@import "/src/styles/variables.scss";

.LoginSelect {
	position: relative;
	display: inline-block;
}

.mobile {
	display: none;
}

.svgIcon {
	// position: absolute;
	// right: 0;
	// transition: 0.3s;
	// top: 0;
	// bottom: 0;
	height: 20px;
	width: 20px;
	// fill: $grayScaleLvl4;
}

.optionBox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	width: 100%;
}

.selectedOption {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 25px 10px 10px;
	font-size: 16px;
	// width: 60px;
	text-align: center;
	min-width: 120px;
	height: 40px;
	color: $black;
	border-radius: 3px;
	border: 1px solid gray;
	cursor: pointer;

	&.active {
		border: 1px solid $grayScaleLvl1;
		border-bottom: none;
		border-radius: 3px 3px 0 0;
		padding-bottom: 10px;
	}

	// &::after {
	// 	content: "";
	// 	position: absolute;
	// 	z-index: 99;
	// 	top: -2.5px;
	// 	right: 10px;
	// 	bottom: 0;
	// 	margin: auto 0;
	// 	color: $black;
	// 	height: 5px;
	// 	width: 5px;
	// 	border-right: 2px solid;
	// 	border-bottom: 2px solid;
	// 	transform: rotate(45deg);
	// 	transition: transform 0.2s;
	// }
}

.selectedOption:hover {
	background-color: $grayScaleLvl5;
	border: 1px solid $grayScaleLvl1;

	&.active {
		border-bottom: none;
	}
}

.selectMenu {
	background-color: $grayScaleLvl3;
	text-align: center;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 101;
	text-align: left;
	background-color: $white;
	border: 1px solid $grayScaleLvl1;
	border-top: none;
	border-radius: 0 0 3px 3px;
	overflow: hidden;
	transition: opacity 0.3s ease;
}

.selectMenu.show {
	opacity: 1;
}

.hide {
	display: none;
}

.option {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 16px;
	cursor: pointer;
	border-bottom: 1px solid $grayScaleLvl1;
	transition: 0.3s;

	& a {
		border-radius: 0 !important;
	}

	&:first-child {
		border-top: 1px solid $grayScaleLvl1;
	}

	&:last-child {
		border-bottom: none;
	}

	&.active {
		background-color: $grayScaleLvl4;
	}
}

.option:hover {
	background-color: $grayScaleLvl5;
	transition: 0.3s;

	& .optionBox.studio {
		color: $brandColor;

		& .svgIcon {
			fill: $brandColor;
		}
	}

	& .optionBox.one {
		color: $brandColorOne;

		& .svgIcon {
			fill: $brandColorOne;
		}
	}
}

@media screen and (max-width: $M) {
	.selectedOption {
		// width: 60px;
		min-width: 100px;
	}

	.normal {
		display: none;
	}

	.mobile {
		display: block;
	}
}

.enter {
	overflow: hidden;
	opacity: 1;
	max-height: 1px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.enterActive {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.exit {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 200ms ease-in-out, max-height 200ms ease-in-out;
}

.exitActive {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 200ms ease-in-out, max-height 200ms;
}
