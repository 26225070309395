@import "/src/styles/variables.scss";

.container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10000;
	overflow-y: scroll;
}

.content__container {
	width: 100%;
	height: auto;
	background-color: $white;
	margin: 100px auto;
	position: relative;
	max-width: $L;
	width: 80vw;
}

.content__wrapper {
	margin: 0 auto;
	z-index: 100000;
	padding: 20px;
	position: relative;
}

.exit__button {
	position: absolute;
	top: -35px;
	right: 0;
}

.saveButton {
	text-align: center;
}
