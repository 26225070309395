@import "/src/styles/variables.scss";

.separator {
	position: relative;

	&.default.x8 {
		height: 0.5rem;
	}

	&.default.x16 {
		height: 1rem;
	}

	&.default.x32 {
		height: 2rem;
	}

	&.default.x64 {
		height: 4rem;
	}

	&.default.x128 {
		height: 8rem;
	}

	&.default.x256 {
		height: 16rem;
	}

	&.margin.x8 {
		margin: 0.5rem 0;
	}

	&.margin.x16 {
		margin: 1rem 0;
	}

	&.margin.x32 {
		margin: 2rem 0;
	}

	&.margin.x64 {
		margin: 4rem 0;
	}

	&.margin.x128 {
		margin: 8rem 0;
	}

	&.margin.x256 {
		margin: 16rem 0;
	}

	&.padding.x8 {
		padding: 0.5rem 0;
	}

	&.padding.x16 {
		padding: 1rem 0;
	}

	&.padding.x32 {
		padding: 2rem 0;
	}

	&.padding.x64 {
		padding: 4rem 0;
	}

	&.padding.x128 {
		padding: 8rem 0;
	}

	&.padding.x256 {
		padding: 16rem 0;
	}
}
