@import "/src/styles/variables.scss";

.big__list {
	&_item {
		font-size: 16px;
		width: max-content;
		padding: 5px;
	}
}

.small__list {
	padding: 0;

	&_item {
		padding: 10px 0 0 0;
		width: max-content;
	}
}

.svgIcon {
	position: absolute;
	right: 0;
	transition: 0.3s;
	top: 0;
	bottom: 0;
	transform: scale(0.6);
	fill: $grayScaleLvl4;
}

.link__style_big {
	padding: 0;
	text-transform: none;
	font-size: 20px;
	transition: 0.3s;

	&:hover {
		color: $white;
	}
}

.title {
	color: $white;
}

.link__style {
	padding: 0;
	text-transform: none;
	font-size: 16px;
	transition: 0.3s;
	position: relative;
	display: inline;

	&.external {
		padding-right: 24px;
	}

	&:hover {
		color: $white;

		& .svgIcon {
			fill: $white;
		}
	}
}

@media screen and (max-width: $L) {
	.big__list_item {
		margin-top: 20px;
		min-width: 150px;
	}
}

@media screen and (max-width: $M) {
	.big__list_item {
		line-break: anywhere;
		margin-top: 20px;
		min-width: unset;
		flex: 0 0 45%;
		width: 50%;
		min-width: unset;
	}
}
