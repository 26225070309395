@import "/src/styles/variables.scss";

/* Header styles */
.header {
	height: 80px;
	width: 100%;
	background-color: $white;
	display: flex;
	align-items: center;
	z-index: 101;
	position: sticky;
	top: 0;
	z-index: 1000;
	display: none;

	/* Navigation styles */
	&__nav {
		max-width: 1280px;
		padding: 0 5%;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: end;
		flex-direction: row-reverse;
		align-items: center;
		height: 80px;
	}
}

.headerButtons {
	display: flex;
	gap: 16px;
}

.linkWithImageContainer {
	display: flex;
	align-items: center;
	gap: 3px;

	& span {
		height: 100%;
		display: block;

		& svg {
			height: 100%;
			display: block;
		}
	}

	& svg {
		transition: fill 0.3s;
		fill: $brandColor;
	}
}

.navbarMenu {
	position: fixed;
	top: 0;
	left: 0;
	width: 101%;
	height: 100%;
	background-color: $white;
	transition: transform 0.5s ease-in-out;
	z-index: 999;
}

.open {
	transform: translateY(59px);
}

.list {
	list-style: none;
	padding: 25px 0 0 25px;
	margin: 0;
	height: 100%;
	padding-bottom: 85px;
	overflow-y: auto;
}

.listItem {
	margin: 24px 0;
	position: relative;
	overflow: hidden;
	text-align: left;
}

.link {
	display: block;
	padding: 20px;
	font-size: 20px;
	color: $black;
	text-decoration: none;
}

.subList {
	overflow: hidden;

	list-style: none;
	padding: 0;
	margin: 0;
}

.subListItem {
	margin: 10px 0;
}

.subLink {
	display: block;
	padding: 10px 20px;
	font-size: 16px;
	color: $black;
	text-decoration: none;
	pointer-events: none;
}

.spacer {
	width: 25px;
}

//   Buutton
.burgerButton {
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	padding: 5px;
	background-color: $grayScaleLvl5;
	border-radius: 3px;
}

.line {
	width: 100%;
	height: 3px;
	background-color: $black;
	border-radius: 10px;
	transition: 0.3s;
}

.burgerButton.active .line:first-child {
	transition: 0.3s;
	transform: translateY(13px) rotate(45deg);
}

.burgerButton.active .line:nth-child(2) {
	opacity: 0;
	transition: 0.01s;
}

.burgerButton.active .line:last-child {
	transition: 0.3s;
	transform: translateY(-14px) rotate(-45deg);
}

.svgIcon {
	transition: 0.3s;
	position: absolute;
	right: 0;
	top: 0;
	fill: $grayScaleLvl2;
	bottom: 0;
	transform: scale(0.6);

	&.withIcon {
		top: 4px;
	}
}

.LinksList {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: $flexGap16;
}

.buttonLink {
	color: $grayScaleLvl2;
	text-align: left;
	font-weight: 400;

	&.external {
		padding-right: 24px;
	}

	&.active {
		color: $brandColorShadeLvl1;
	}
}

.button {
	& .svgIcon {
		fill: $black;
	}

	color: $black;
	font-weight: 600;
	text-transform: uppercase;
	text-align: left;
	font-size: 1.25rem !important;

	&.external {
		padding-right: 24px;
	}

	&.active {
		color: $brandColorShadeLvl1;
	}

	&.expanded {
		padding-right: 22px;

		&::after {
			content: "";
			position: absolute;
			z-index: 99;
			top: 2.5px;
			right: 10px;
			bottom: 0;
			margin: auto 0;
			color: $black;
			height: 5px;
			width: 5px;
			border-right: 2px solid;
			border-bottom: 2px solid;
			transform: rotate(-45deg);
			transition: transform 0.2s;
		}

		&.active {
			color: $black;

			&::after {
				transform: rotate(45deg);
				top: -2.5px;
				transition: transform 0.2s;
			}
		}
	}
}

.container {
	width: 100%;
	position: sticky;
	display: none;
	background-color: $white;
	top: 0;
	z-index: 1000;
	transition: 0.3s ease-in-out;
	box-shadow: $boxShadowNormal;

	.subHeader {
		background-color: $brandColor;
	}

	&.show {
		opacity: 1;
		transform: translateY(0);
		transition: 0.3s;
	}

	&.hide {
		transform: translateY(-125px);
		opacity: 1;
		transition: 0.3s;

		.header {
			box-shadow: none;
		}
	}
}

@media screen and (min-width: $XL) {
	.header {
		&__nav {
			width: $XL;
			padding: 0 calc($XL / 100 * 5);
		}
	}
}

@media screen and (max-width: $L) {
	.container {
		&.hide {
			transform: translateY(-80px);
		}
	}

	.header {
		&__nav {
			flex-wrap: wrap;
		}
	}

	.menuBox {
		align-items: center;
		flex-direction: row;
	}

	.menuBoxTitle {
		padding: 0px 10px;
	}
}

@media screen and (max-width: $M) {
	.header {
		height: 80px;

		&__nav {
			padding: 0 5% 0 0;
			height: 80px;
			flex-direction: row-reverse;
			justify-content: end;
			flex-wrap: nowrap;
			background-color: $white;
		}
	}

	.buttons__container {
		padding-right: 20px;
	}
}

//animation
// .sublist {
// 	max-height: 0px;
// 	overflow: hidden;
// 	transition: opacity 0.7s ease-in-out;
// 	transition: max-height 0.7s ease-in-out;
// }
.listEnter {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listEnterActive {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listExit {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}

.listExitActive {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 300ms ease-in-out, max-height 300ms;
}

.menuEnter {
	opacity: 0;
	transform: translateY(-100%);
}

.menuEnterActive {
	opacity: 1;
	transform: translateY(59px);

	transition: opacity 300ms ease-in,
		transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
}

.menuExit {
	opacity: 1;
	transform: translateY(59px);
}

.menuExitActive {
	opacity: 0;
	transform: translateY(-100%);
	transition: opacity 300ms ease-in,
		transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
}

@media screen and (max-width: $L) {
	.header {
		display: flex;
	}
}

@media screen and (max-width: $L) {
	.container {
		display: block;
	}
}
