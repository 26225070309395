#Circle_svg__CreateBold {
	opacity: 0;
	transition: 0.3s;

	&.active {
		opacity: 1;
	}
}

#Circle_svg__CreateBold:hover {
	opacity: 1;
	transition: 0.3s;
	cursor: pointer;
}

#Circle_svg__EditBold {
	opacity: 0;
	transition: 0.3s;

	&.active {
		opacity: 1;
	}
}

#Circle_svg__EditBold:hover {
	opacity: 1;
	transition: 0.3s;
	cursor: pointer;
}

#Circle_svg__ShareBold {
	opacity: 0;
	transition: 0.3s;

	&.active {
		opacity: 1;
	}
}

#Circle_svg__ShareBold:hover {
	opacity: 1;
	transition: 0.3s;
	cursor: pointer;
}

#Circle_svg__ManageBold {
	opacity: 0;
	transition: 0.3s;

	&.active {
		opacity: 1;
	}
}

#Circle_svg__ManageBold:hover {
	opacity: 1;
	transition: 0.3s;
	cursor: pointer;
}

#Circle_svg__AnalyseBold {
	opacity: 0;
	transition: 0.3s;

	&.active {
		opacity: 1;
	}
}

#Circle_svg__AnalyseBold:hover {
	opacity: 1;
	transition: 0.3s;
	cursor: pointer;
}
