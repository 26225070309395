@import "/src/styles/variables.scss";

// .button {
// 	padding: 5px 10px;
// 	font-size: $div_M_FS;
// 	border-radius: 5px;
// 	&:hover {
// 		cursor: pointer;
// 	}
// }

// @media screen and (max-width: M) {
// 	.button {
// 		font-size: 18px;
// 		font-size: $div_Sm_FS;
// 	}
// }

.btn {
	border-radius: 3px;
	position: relative;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
	}

	&.small {
		padding: 5px 8px;
	}

	&.default {
		font-size: 16px;
		padding: 10px 20px;
	}

	&.middle {
		font-size: 18.5px;
		padding: 15px 30px;
	}

	&.large {
		font-size: 21px;
		padding: 10px 40px;
	}
	&.disabled {
		background-color: $grayScaleLvl3 !important;

		&:hover {
			background-color: $grayScaleLvl3 !important;
			cursor: default;
		}
	}

	&.primary {
		border: none;
		color: $grayScaleLvl6;

		&.one {
			background-color: $brandColorOne;
		}

		&.studio {
			background-color: $brandColor;
		}

		text-decoration: none;

		&.one {
			&:hover {
				background-color: $brandColorOneShadeLvl1;
				transition: 0.3s;
				color: $grayScaleLvl6;
			}
		}

		&.studio {
			&:hover {
				background-color: $brandColorShadeLvl1;
				transition: 0.3s;
				color: $grayScaleLvl6;
			}
		}

		&.disabled {
			background-color: $grayScaleLvl3;
		}
	}

	&.secondary {
		&.one {
			color: $brandColorOne;
			border: 1px $brandColorOne solid;
		}

		&.studio {
			color: $brandColor;
			border: 1px $brandColor solid;
		}

		background-color: $grayScaleLvl6;
		text-decoration: none;

		&.one {
			&:hover {
				transition: 0.3s;
				color: $brandColorOneShadeLvl1;
				border: 1px $brandColorOneShadeLvl1 solid;
			}
		}

		&.studio {
			&:hover {
				transition: 0.3s;
				color: $brandColorShadeLvl1;
				border: 1px $brandColorShadeLvl1 solid;
			}
		}

		&.disabled {
			color: $grayScaleLvl3;
			border: 1px $grayScaleLvl3 solid;
			background-color: $grayScaleLvl6;
		}
	}

	&.readMoreWhite {
		text-align: left;
		padding: 0;
		transition: 0.3s;
		text-decoration: none;

		&.popup {
			transition: transform 0.3s;

			&.one {
				color: $brandColorOne;
			}

			&.studio {
				color: $brandColor;
			}

			&.studio {
				&:hover {
					text-decoration: underline;
					transition: transform 0.3s;
					color: $brandColorShadeLvl1;
				}
			}

			&.one {
				&:hover {
					text-decoration: underline;
					transition: transform 0.3s;
					color: $brandColorOneShadeLvl1;
				}
			}
		}

		&.link {
			transition: 0.3s;
			padding: 0;
			color: $grayScaleLvl6;

			&:hover {
				transition: 0.3s;
				text-decoration: underline;

				&.one {
					color: $brandColorOne;
				}

				&.studio {
					color: $brandColor;
				}
			}
		}
	}

	&.readMore {
		text-align: left;
		padding: 0;
		text-decoration: none;

		&.popup {
			transition: transform 0.3s;

			&.one {
				color: $brandColorOne;
			}

			&.studio {
				color: $brandColor;
			}

			&.studio {
				&:hover {
					text-decoration: underline;
					transition: transform 0.3s;
					color: $brandColorShadeLvl1;
				}
			}

			&.one {
				&:hover {
					text-decoration: underline;
					transition: transform 0.3s;
					color: $brandColorOneShadeLvl1;
				}
			}
		}

		&.link {
			position: relative;
			transition: transform 0.3s;

			&:before {
				content: "";
				z-index: 0;
				position: absolute;
				top: 3px;
				right: 8px;
				bottom: 0;
				margin: auto 0;
				height: 6px;
				width: 6px;
				border-right: 1.8px solid;
				border-bottom: 1.8px solid;
				transform: rotate(-45deg) translateX(0px) translateY(0px);
				transition: transform 0.3s;
			}

			transition: transform 0.3s;
			padding: 0 17px 0 0;

			&.one {
				color: $brandColorOne;
			}

			&.studio {
				color: $brandColor;
			}

			&:hover {
				text-decoration: underline;
				transition: transform 0.3s;

				&.one {
					color: $brandColorOneShadeLvl1;
				}

				&.studio {
					color: $brandColorShadeLvl1;
				}

				&.one {
					&:before {
						color: $brandColorOne;
						transition: transform 0.3s;
						transform: rotate(-45deg) translateX(5px)
							translateY(5px);
					}
				}

				&.studio {
					&:before {
						color: $brandColor;
						transition: transform 0.3s;
						transform: rotate(-45deg) translateX(5px)
							translateY(5px);
					}
				}
			}
		}
	}

	&.unstyled {
		text-decoration: none;
		padding: 0;
		border: none;
		background-color: transparent;
	}

	&.exit {
		padding: 0;
		border: none;
		background: transparent;
		position: relative;
		width: 25px;
		height: 25px;
		text-decoration: none;

		&::before {
			position: absolute;
			content: "";
			transform: rotate(45deg);
			bottom: 50%;
			left: 0;
			height: 2px;
			width: 100%;
			background-color: $grayScaleLvl6;
		}

		&::after {
			position: absolute;
			content: "";
			transform: rotate(135deg);
			bottom: 50%;
			left: 0;
			height: 2px;
			width: 100%;
			background-color: $grayScaleLvl6;
		}
	}

	&.play {
		&.one {
			&::before {
				background-color: $brandColorOne;
			}
		}

		&.studio {
			&::before {
				background-color: $brandColor;
			}
		}

		&::before {
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			margin: auto 0;
			border-radius: 50%;
		}

		text-decoration: none;

		&::after {
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			margin: auto 0;
			background: $white;
			clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
			transform: rotate(90deg);
		}

		&:hover {
			&.one {
				&::before {
					transition: 0.3s;
					background-color: $brandColorOneShadeLvl1;
				}
			}

			&.studio {
				&::before {
					transition: 0.3s;
					background-color: $brandColorShadeLvl1;
				}
			}

			&::after {
				transition: 0.3s;
				background-color: $white;
			}
		}

		&.default {
			padding-left: 30px !important;

			&::before {
				left: 5px;
				width: 20px;
				height: 20px;
			}

			&::after {
				left: 12px;
				width: 8px;
				height: 8px;
			}
		}

		&.middle {
			padding-left: 45px !important;

			&::before {
				left: 7px;
				width: 30px;
				height: 30px;
			}

			&::after {
				left: 18px;
				width: 12px;
				height: 12px;
			}
		}

		&.large {
			padding-left: 70px !important;

			&::before {
				left: 12.5px;
				width: 50px;
				height: 50px;
			}

			&::after {
				left: 30px;
				width: 20px;
				height: 20px;
			}
		}
	}

	&.expandedSecondary {
		text-align: left;
		width: 100%;
		z-index: 10;
		text-decoration: none;
		border-bottom: 1px solid $grayScaleLvl4 !important;
		padding: 10px 0;
		transition: 0.3s;

		&.active {
			padding-left: 25px !important;
			border-bottom: none !important;

			&.one {
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: 0;
					left: 0;
					bottom: 0;
					margin: auto 0;
					height: 100%;
					width: 15px;
					background-color: $brandColorOne;
					transition: 0.3s;
				}
			}

			&.studio {
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: 0;
					left: 0;
					bottom: 0;
					margin: auto 0;
					height: 100%;
					width: 15px;
					background-color: $brandColor;
					transition: 0.3s;
				}
			}
		}

		&.unActive {
			padding-right: 15px !important;
			padding-left: 10px !important;

			&.one {
				&:hover::before {
					transition: 0.3s;
					background-color: $brandColorOne;
				}
			}

			&.studio {
				&:hover::before {
					transition: 0.3s;
					background-color: $brandColor;
				}
			}

			&::before {
				content: "";
				z-index: -1;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				background-color: black;
				margin: auto 0;
				height: 100%;
				width: 3px;
				transition: 0.3s;
			}
		}
	}

	&.expand {
		text-align: left;
		width: 100%;
		z-index: 10;
		text-decoration: none;

		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			top: -3px;
			right: 10px;
			bottom: 0;
			margin: auto 0;
			height: 10px;
			width: 10px;
			border-right: 3px solid;
			border-bottom: 3px solid;
			transform: rotate(-45deg);
			transition: transform 0.2s;
		}

		&.unActive {
			color: gray;

			&:hover {
				color: $black;
			}

			&.one {
				&:hover::before {
					color: $brandColorOne;
				}
			}

			&.studio {
				&:hover::before {
					color: $brandColor;
				}
			}

			&::before {
				transform: rotate(-45deg);
			}
		}

		&.active {
			color: $black;

			&::before {
				transform: rotate(45deg);
			}
		}
	}
}
