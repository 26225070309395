@import "/src/styles/variables.scss";

.container {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 98;
	transition: opacity 0.2s ease-in-out;
	background-color: $black;
	color: $black;
	cursor: pointer;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	transition: transform 0.2s ease-in-out;
	&:hover {
		transform: rotate(360deg);
	}
}

.triangle {
	width: 0;
	height: 0;
	transition: transform 0.2s ease-in-out;
	transform: rotate(0deg);
	cursor: pointer;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		left: -12px;
		top: -5px;
		bottom: 0;
		margin: auto 0;
		width: 25px;
		height: 20px;
		background: $white;
		-webkit-clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
		transform: rotate(0deg);
		clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
	}
}

.icon {
	font-size: 20px;
	color: $black;
	position: relative;
	top: -4px;
	left: -1px;
}
