@import "/src/styles/variables.scss";

.container {
	padding: 25px 0px;
	border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
	border-top: 1px rgba(255, 255, 255, 0.5) solid;
}

.big__list {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: $L) {
	.big__list {
		flex-wrap: wrap;
		flex-direction: column;
		max-height: 400px;
		align-items: left;
	}
}
@media screen and (max-width: $M) {
	.big__list {
		max-height: 760px;
	}
}

@media screen and (max-width: $mobileCardMaxWidth) {
	.big__list {
		max-height: unset;
	}
}
