@import "/src/styles/variables.scss";

/* ResponsiveImage.module.scss */
.container {
	width: 100%;
	height: auto;
	margin: 0 auto;
	line-height: 0;
}

.image {
	height: auto;
	width: 100%;
	object-fit: cover;
}
