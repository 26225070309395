@import "/src/styles/variables.scss";

.checkboxLabel {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.checkbox {
	margin-right: 8px;
}

.label {
	padding-bottom: 0;
}

.container {
	border: 1px solid black;
	padding: 0.5rem;
	border-radius: 8px;
}
