@import "/src/styles/variables.scss";

.header {
	position: sticky;
	background-color: $white;
	top: 0;
	z-index: 999;
	transition: 0.3s ease-in-out;
	box-shadow: $boxShadowNormal;

	&.show {
		opacity: 1;
		transform: translateY(0);
		transition: 0.3s;

		.subHeader {
			background-color: $grayScaleLvl5;
			transition: 0.3s;
		}
	}
}

.logo {
	height: 100%;
	width: auto;
}

.logoBox {
	height: 80px;
	width: auto;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerText {
	&.tablet {
		font-size: 16px;
		display: none;
		color: $grayScaleLvl3;
	}
}
